import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup:`}</p>
    <p>{`Snatch Balance 4×2`}</p>
    <p>{`Muscle Snatch 4×2`}</p>
    <p>{`then,`}</p>
    <p>{`Hang Power Snatch 4×6\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`CrossFit Games Individual Event 9`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`Power Snatch (105/75)`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      